<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover text-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <h1
        class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
        style="font-size: 150px;"
      >
        403
      </h1>
      <p class="font-size-h3 font-weight-light">
        {{ $t('errors.403')}}
      </p>

      <!-- end:: Content -->

    </div>
    <div class="col-12 text-center">
      <button type="button" class="btn btn-primary"  style="width: 10%" @click="clearSession"> Anasayfa </button>
    </div>
  </div>
</template>

<script>
import {LOGOUT} from "@/core/services/store/auth.module";

export default {
  name: "Error-1",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg1.jpg";
    }
  },

  methods: {
    clearSession() {
      this.$store.dispatch(LOGOUT);
      this.$router.push({'name': 'login'})
    }
  }
};
</script>
